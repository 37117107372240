import React from 'react';
import config from './config';

function App() {
  return (
    <div>
      <h1>{config.APP_NAME}</h1>
      <p>Application en cours de construction</p>
    </div>
  );
}

export default App;